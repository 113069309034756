import React, { useState, useEffect } from "react"
import SEO from "../../components/seo"
import { fabric } from "fabric"
import { useStaticQuery, graphql } from "gatsby"
import "../../components/layout.css"

class ImageSizerCanvas extends React.PureComponent {
  mySetZoom(zoom = 1, x = 0, y = 0) {
    this.canvas.zoomToPoint({ x: x, y: y }, zoom)

    // Keep the pointers the same size and the line the same width
    // as we zoom
    this.canvas.myPointers.forEach(pointer => {
      pointer.set({ scaleX: 1 / zoom, scaleY: 1 / zoom })
    })
    this.canvas.myLines.forEach(line => {
      line.set({ strokeWidth: 1 / zoom })
    })
  }

  componentDidMount() {
    this.canvas = new fabric.Canvas("main-canvas", {
      selection: false,
    })

    const fitResponsiveCanvas = () => {
      // canvas dimensions
      let canvasSize = {
        width: 800,
        height: 600,
      }
      // canvas container dimensions
      let containerSize = {
        width: document.getElementById("canvas-container").offsetWidth,
        height: document.getElementById("canvas-container").offsetHeight,
      }
      let scaleRatio = Math.min(
        containerSize.width / canvasSize.width,
        containerSize.height / canvasSize.height
      )
      this.canvas.setWidth(containerSize.width)
      this.canvas.setHeight(containerSize.height)
      // set canvas zoom aspect
      // console.log("current zoom", canvas.getZoom(), "scaleRatio", scaleRatio)
      // canvas.setZoom(canvas.getZoom() * scaleRatio)
    }
    fitResponsiveCanvas()
    window.onresize = fitResponsiveCanvas

    // Zoom
    this.canvas.on("mouse:wheel", opt => {
      const delta = opt.e.deltaY
      const pointer = this.canvas.getPointer(opt.e)
      let zoom = this.canvas.getZoom()
      zoom = zoom - delta / 200
      if (zoom > 20) zoom = 20
      if (zoom < 0.01) zoom = 0.01

      this.mySetZoom(zoom, opt.e.offsetX, opt.e.offsetY)

      opt.e.preventDefault()
      opt.e.stopPropagation()
    })

    // Pan
    this.canvas.on("mouse:down", function(opt) {
      console.log("mouse:down", opt)
      const evt = opt.e
      const target = opt.target
      if (!target) {
        this.isDragging = true
        this.selection = false
        this.lastPosX = evt.clientX
        this.lastPosY = evt.clientY
      }
    })
    this.canvas.on("mouse:move", function(opt) {
      if (this.isDragging) {
        const e = opt.e
        this.viewportTransform[4] += e.clientX - this.lastPosX
        this.viewportTransform[5] += e.clientY - this.lastPosY
        this.requestRenderAll()
        this.lastPosX = e.clientX
        this.lastPosY = e.clientY
      }
    })
    this.canvas.on("mouse:up", function(opt) {
      this.isDragging = false
      // this.selection = true;
      // there's some bug that after dragging the canvas, the pointers become
      // unselectable until zooming again.
      // I don't know why. Fix later.
      this.forEachObject(function(o) {
        o.selectable = true
        o.setCoords()
      })
    })

    fabric.Object.prototype.originX = fabric.Object.prototype.originY = "center"

    this.canvas.on("object:moving", e => {
      const p = e.target
      p.line1 && p.line1.set({ x2: p.left, y2: p.top })
      p.line2 && p.line2.set({ x1: p.left, y1: p.top })
      p.line3 && p.line3.set({ x1: p.left, y1: p.top })
      p.line4 && p.line4.set({ x1: p.left, y1: p.top })
      this.canvas.renderAll()
    })

    this.canvas.on("object:modified", e => {
      const p = e.target
      console.log("Moved", p.id, p.left, p.top)
      p.setter({ x: p.left, y: p.top })
    })

    this.setupCanvas()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.imgSrc !== prevProps.imgSrc) {
      if (this.props.imgSrc) {
        this.canvas.setBackgroundImage(
          this.props.imgSrc,
          this.canvas.renderAll.bind(this.canvas),
          {
            // Needed to position backgroundImage at 0/0
            originX: "left",
            originY: "top",
          }
        )
      }
    }
    // this.setupCanvas()
  }

  setupCanvas() {
    function makePointer(setter, left, top, color, line1, line2, line3, line4) {
      const radius = 10

      const circle = new fabric.Circle({
        left: left,
        top: top,
        strokeWidth: 3,
        radius: radius,
        fill: "rgba(0,0,0,0)",
        stroke: color,
        originX: "center",
        originY: "center",
      })

      const hLine = new fabric.Line([left - radius, top, left + radius, top], {
        fill: "#000",
        stroke: "#000",
        strokeWidth: 1,
        originX: "center",
        originY: "center",
      })

      const hLineDash = new fabric.Line(
        [left - radius, top, left + radius, top],
        {
          fill: "#fff",
          stroke: "#fff",
          strokeDashArray: [1, 1],
          strokeWidth: 1,
          originX: "center",
          originY: "center",
        }
      )

      const vLine = new fabric.Line([left, top - radius, left, top + radius], {
        fill: "#000",
        stroke: "#000",
        strokeWidth: 1,
        originX: "center",
        originY: "center",
      })

      const vLineDash = new fabric.Line(
        [left, top - radius, left, top + radius],
        {
          fill: "#fff",
          stroke: "#fff",
          strokeDashArray: [1, 1],
          strokeWidth: 1,
          originX: "center",
          originY: "center",
        }
      )

      const group = new fabric.Group(
        [circle, hLine, hLineDash, vLine, vLineDash],
        {
          // left: left,
          // top: top,
          hasControls: false,
          hasBorders: false,
        }
      )
      group.setter = setter

      group.line1 = line1
      group.line2 = line2
      group.line3 = line3
      group.line4 = line4

      return group
    }

    function makeLine(coords) {
      return new fabric.Line(coords, {
        fill: "red",
        stroke: "red",
        strokeWidth: 1,
        selectable: false,
        evented: false,
      })
    }

    if (this.props.imgSrc) {
      this.canvas.setBackgroundImage(
        this.props.imgSrc,
        this.canvas.renderAll.bind(this.canvas),
        {
          // Needed to position backgroundImage at 0/0
          originX: "left",
          originY: "top",
        }
      )
    }

    const diameterLine = makeLine([
      this.props.diameterA.x,
      this.props.diameterA.y,
      this.props.diameterB.x,
      this.props.diameterB.y,
    ])

    this.canvas.myLines = [diameterLine]

    this.canvas.add(diameterLine)

    const pointers = [
      makePointer(
        this.props.setDiameterA,
        diameterLine.get("x1"),
        diameterLine.get("y1"),
        "#4299e1",
        null,
        diameterLine
      ),
      makePointer(
        this.props.setDiameterB,
        diameterLine.get("x2"),
        diameterLine.get("y2"),
        "#4299e1",
        diameterLine
      ),

      makePointer(
        this.props.setLengthBase,
        this.props.lengthBase.x,
        this.props.lengthBase.y,
        "#9f7aea"
      ),
      makePointer(
        this.props.setLengthTip,
        this.props.lengthTip.x,
        this.props.lengthTip.y,
        "#ed8936"
      ),
    ]
    this.canvas.myPointers = pointers
    this.canvas.add(...pointers)
  }

  render() {
    return (
      <div className="relative w-full h-full" id="canvas-container">
        <canvas id="main-canvas" className="absolute" />
      </div>
    )
  }
}

const ImageSizerPage = () => {
  const data = useStaticQuery(graphql`
    query MyQuery4 {
      files: allFile(filter: { name: { in: ["size-guide"] } }) {
        nodes {
          childImageSharp {
            fixed(width: 200) {
              src
            }
          }
        }
      }
    }
  `)

  const sizeGuideSrc = data.files.nodes.map(
    obj => obj.childImageSharp.fixed.src
  )[0]

  const [diameterA, setDiameterA] = useState({ x: 100, y: 100 })
  const [diameterB, setDiameterB] = useState({ x: 150, y: 100 })
  const [lengthTip, setLengthTip] = useState({ x: 200, y: 200 })
  const [lengthBase, setLengthBase] = useState({ x: 200, y: 250 })
  const [img, setImg] = useState({})
  const [isFetching, setIsFetching] = useState(false)

  const fetchImgData = () => {
    setIsFetching(true)
    fetch(`/.netlify/functions/next-image`)
      .then(response => response.json()) // parse JSON from request
      .then(imgData => {
        setImg(imgData)
        setIsFetching(false)
      })
  }
  useEffect(fetchImgData, [])

  return (
    <div className={`flex ${isFetching ? "opacity-25" : ""}`}>
      <SEO title="Image Sizer" />
      <div className="w-1/6 border-r pl-2 text-sm">
        <button
          className="border bg-pink-200 rounded px-6 py-4 mt-4 active:bg-pink-400"
          disabled={isFetching}
          onClick={() => {
            // TODO: error handling
            setIsFetching(true)
            postData("/.netlify/functions/save-image-size", {
              recordId: img.RecordId,
              imageMeasurement: {
                diameterA,
                diameterB,
                lengthBase,
                lengthTip,
              },
            }).then(data => {
              console.log(data) // JSON data parsed by `response.json()` call
              fetchImgData()
            })
          }}
        >
          Save and Next
        </button>
        <button
          className="border bg-blue-200 rounded px-6 py-4 mt-4 active:bg-blue-400"
          disabled={isFetching}
          onClick={() => {
            // TODO: error handling
            setIsFetching(true)
            postData("/.netlify/functions/save-image-size", {
              recordId: img.RecordId,
              imageMeasurement: {},
            }).then(data => {
              console.log(data) // JSON data parsed by `response.json()` call
              fetchImgData()
            })
          }}
        >
          Skip
        </button>
        <p className="text-xs text-gray-500">Record ID: {img.RecordId}</p>
        <p>Name: {img.Name}</p>
        <p>Notes: {img.Notes}</p>
        <p>
          <span className="text-blue-500 font-black">●</span> Diameter A:{" "}
          {diameterA.x.toFixed(1)}, {diameterA.y.toFixed(1)}.
        </p>
        <p>
          <span className="text-blue-500 font-black">●</span> Diameter B:{" "}
          {diameterB.x.toFixed(1)}, {diameterB.y.toFixed(1)}
        </p>
        <p>
          <span className="text-purple-500 font-black">●</span> Base:{" "}
          {lengthBase.x.toFixed(1)}, {lengthBase.y.toFixed(1)}
        </p>
        <p>
          <span className="text-orange-500 font-black">●</span> Tip:{" "}
          {lengthTip.x.toFixed(1)}, {lengthTip.y.toFixed(1)}
        </p>
        <img src={sizeGuideSrc} alt="Guide on where to put pointers" />
      </div>
      <div className="w-5/6 h-screen">
        <ImageSizerCanvas
          diameterA={diameterA}
          diameterB={diameterB}
          setDiameterA={setDiameterA}
          setDiameterB={setDiameterB}
          lengthTip={lengthTip}
          lengthBase={lengthBase}
          setLengthTip={setLengthTip}
          setLengthBase={setLengthBase}
          imgSrc={img.ImageURL}
        />
      </div>
    </div>
  )
}

// Example POST method implementation:
async function postData(url = "", data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *client
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  })
  return await response.json() // parses JSON response into native JavaScript objects
}

export default ImageSizerPage
